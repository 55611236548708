import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.less']
})
export class AppComponent implements OnInit {
  title = 'admin-panel';

  scaleStyle;
  baseSize = 1920;

  onResize(event) {
    this.scalePage();
  }

  scalePage() {
    const w = window.innerWidth;
    const scaleStyle = {
      'width': `${this.baseSize}px`,
      'transform': `scale(${w / this.baseSize})`,
      'transform-origin': `0 0`,
      'height': `${this.baseSize / w * 100}%`,
    };
    // this.scaleStyle = scaleStyle;
    Object.assign(document.body.style, scaleStyle);
  }

  ngOnInit() {
    this.scalePage();
  }
}
